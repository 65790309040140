import React from "react"
import { withStyles, createStyles, WithStyles } from "@material-ui/core"

import BaseStar, {
  BaseProps as BaseStarBaseProps,
} from "../../../../components/Stars/Star"
import { useIsDarkMode } from "../../../../utils/colors"
import { getSizeStyles } from "../../../../utils/styles"

const styles = () => {
  return createStyles({
    root: {
      ...getSizeStyles("1.75rem"),
    },
    selected: {
      fill: "#FFC107",
    },
    unselected: {
      fill: "#FFFFFF80",
    },
    unselectedDark: {
      fill: "#47596A7F",
    },
  })
}

export interface Props extends BaseStarBaseProps, WithStyles<typeof styles> {
  selectedStars: number
}

const Star = ({ selectedStars, index, classes, ...other }: Props) => {
  const isDarkMode: boolean = useIsDarkMode()

  const getColorSchemeClassName = (): string => {
    const isSelected: boolean = index < selectedStars
    if (isSelected) {
      return classes.selected
    } else if (isDarkMode) {
      return classes.unselectedDark
    }
    return classes.unselected
  }

  const getClassName = (): string => {
    const colorClassName: string = getColorSchemeClassName()
    return `${colorClassName} ${classes.root}`
  }

  return (
    <BaseStar index={index} classes={{ root: getClassName() }} {...other} />
  )
}

export default withStyles(styles)(Star)
