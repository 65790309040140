import React from "react"
import {
  Typography,
  withStyles,
  WithStyles,
  createStyles,
  Theme,
} from "@material-ui/core"

import Product from "../../types/Product"

const styles = ({ breakpoints: { up } }: Theme) => {
  const lg = up("lg")
  const xl = up("xl")
  return createStyles({
    root: {
      marginBottom: "1.5rem",
      [xl]: {
        marginBottom: "2.5rem",
      },
    },
    subtitle: {
      fontSize: "1.5rem",
      fontWeight: 300,
      lineHeight: "2.5rem",
      marginBottom: "1.5rem",
      [lg]: {
        fontSize: "2.25rem",
        lineHeight: "2.5rem",
      },
    },
    description: {
      [lg]: {
        fontSize: "1.5rem",
        lineHeight: "2rem",
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {
  subtitle: Product["subtitle"]
  description: Product["description"]
}

const Body = ({ classes, subtitle, description }: Props) => {
  return (
    <div className={classes.root}>
      <Typography component="h2" className={classes.subtitle}>
        {subtitle}
      </Typography>
      <Typography variant="body1" className={classes.description}>
        {description}
      </Typography>
    </div>
  )
}

export default withStyles(styles)(Body)
