import React from "react"
import { withStyles, WithStyles, createStyles } from "@material-ui/core"

import { BaseProps } from "./Star"
import { ClassNameMap } from "@material-ui/styles"

const styles = () => {
  return createStyles({
    root: {
      display: "flex",
    },
  })
}

interface StarProps extends BaseProps {
  classes?: ClassNameMap<any>
}

interface Props extends WithStyles<typeof styles> {
  stars: number
  Star: React.FunctionComponent<StarProps>
}

const Stars = ({ classes, stars, Star }: Props) => {
  const addStar = (renderedStars: JSX.Element[], index: number): void => {
    const star: JSX.Element = <Star index={index} key={index} />
    renderedStars.push(star)
  }

  const renderStars = (): JSX.Element[] => {
    let renderedStars: JSX.Element[] = []
    for (let starIndex: number = 0; starIndex < stars; starIndex++) {
      addStar(renderedStars, starIndex)
    }
    return renderedStars
  }

  const renderedStars: JSX.Element[] = renderStars()

  return <div className={classes.root}>{renderedStars}</div>
}

export default withStyles(styles)(Stars)
