import React from "react"
import {
  withStyles,
  WithStyles,
  createStyles,
  Typography,
  Theme,
} from "@material-ui/core"

import Stars from "./Stars"
import Product from "../../../types/Product"

const styles = ({ breakpoints: { up } }: Theme) => {
  const lg = up("lg")
  const xl = up("xl")
  return createStyles({
    root: {
      marginBottom: "3.875rem",
      [xl]: {
        marginBottom: "4.5rem",
      },
    },
    title: {
      marginBottom: "0.6875rem",
      fontSize: "2.1875rem",
      lineHeight: "3.4375rem",
      fontWeight: 300,
      [lg]: {
        fontSize: "3.125rem",
        lineHeight: "3.4375rem",
      },
      [xl]: {
        fontSize: "3.75rem",
        lineHeight: "3.4375rem",
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {
  title: Product["title"]
  stars: Product["stars"]
}

const Info = ({ classes, title, stars }: Props) => {
  return (
    <div className={classes.root}>
      <Typography component="h1" className={classes.title}>
        {title}
      </Typography>
      <Stars stars={stars} />
    </div>
  )
}

export default withStyles(styles)(Info)
