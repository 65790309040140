import React from "react"
import {
  withStyles,
  WithStyles,
  createStyles,
  Button,
  Theme,
} from "@material-ui/core"

import ThumbnailType from "../../../types/Thumbnail"
import { SelectedImageIndex, SelectImage } from "../../types"
import Thumbnail from "../../../components/Thumbnail"

interface SelectedProps {
  index: number
  selectedImageIndex: SelectedImageIndex
}

interface BaseProps extends ThumbnailType, SelectedProps {
  selectImage: SelectImage
}

const getIfIsSelected = ({
  selectedImageIndex,
  index,
}: BaseProps | SelectedProps): boolean => {
  return selectedImageIndex === index
}

interface SelectionStyles {
  normal: string
  selected: string
}

const getWidth = (dimensions: SelectionStyles) => (
  props: BaseProps
): string => {
  const isSelected: boolean = getIfIsSelected(props)
  const baseWidth: string = isSelected ? dimensions.selected : dimensions.normal
  // To account for the margin
  return `calc(${baseWidth} + 0.5rem)`
}

const getHeight = (dimensions: SelectionStyles) => (
  props: BaseProps
): string => {
  const isSelected = getIfIsSelected(props)
  return isSelected ? dimensions.selected : dimensions.normal
}

const getOpacity = (props: BaseProps): number => {
  const isSelected = getIfIsSelected(props)
  return isSelected ? 1 : 0.75
}

const styles = ({ breakpoints: { up } }: Theme) => {
  const lg = up("lg")
  const xl = up("xl")
  return createStyles({
    root: {
      listStyle: "none",
      borderRadius: "0.4375rem",
      opacity: getOpacity,
      flexShrink: 0,
      width: getWidth({ normal: "4.4375rem", selected: "5rem" }),
      [lg]: {
        width: getWidth({ normal: "5.75rem", selected: "6.6875rem" }),
      },
      [xl]: {
        width: getWidth({ normal: "7.4375rem", selected: "8.5625rem" }),
      },
      "& + &": {
        marginLeft: "1rem",
        [lg]: {
          marginLeft: "1.5rem",
        },
        [xl]: {
          marginLeft: "2rem",
        },
      },
    },
    button: {
      height: getHeight({ normal: "5.6875rem", selected: "6.375rem" }),
      [lg]: {
        height: getHeight({ normal: "7.4375rem", selected: "8.375rem" }),
      },
      [xl]: {
        height: getHeight({ normal: "9.4375rem", selected: "10.8125rem" }),
        borderRadius: "0.875rem",
      },
      "&, &:hover": {
        backgroundColor: "white",
      },
    },
    image: {
      "& img": {
        borderRadius: 0,
      },
    },
  })
}

interface Props extends WithStyles<typeof styles>, BaseProps {}

const Image = ({
  classes,
  selectImage,
  index,
  localFile,
  alternativeText,
  selectedImageIndex,
}: Props) => {
  const handleClick = () => {
    selectImage(index)
  }

  const isSelected = getIfIsSelected({ selectedImageIndex, index })

  return (
    <li className={classes.root}>
      <Button
        variant="contained"
        onClick={handleClick}
        className={classes.button}
        aria-pressed={isSelected}
        aria-label="Select image"
      >
        <Thumbnail
          imageData={localFile}
          alt={alternativeText}
          classes={{ root: classes.image }}
        />
      </Button>
    </li>
  )
}

export default withStyles(styles)(Image)
