import React from "react"
import { withStyles, WithStyles, createStyles } from "@material-ui/core"
import {
  getImage,
  ImageDataLike,
  GatsbyImage,
  GatsbyImageProps,
} from "gatsby-plugin-image"

const styles = () => {
  return createStyles({
    root: {
      width: "100%",
      "& img": {
        borderRadius: "1rem",
      },
    },
  })
}

export interface Props
  extends WithStyles<typeof styles>,
    Omit<GatsbyImageProps, "alt" | "image" | "className"> {
  imageData: ImageDataLike
  alt: string
}

const Thumbnail = ({ classes, imageData, alt, ...other }: Props) => {
  const image = getImage(imageData)
  return (
    <GatsbyImage image={image} alt={alt} className={classes.root} {...other} />
  )
}

export default withStyles(styles)(Thumbnail)
