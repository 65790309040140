import React from "react"
import { withStyles, WithStyles, createStyles, Theme } from "@material-ui/core"

import Product from "../../../types/Product"
import { SelectedImageIndex, SelectImage } from "../../types"
import Thumbnail from "../../../types/Thumbnail"
import Image from "./Image"
import { showLogo } from "../../../components/Layout/NavWithLogo/styles"

const styles = ({ breakpoints: { up } }: Theme) => {
  const showLogoUp = up(showLogo)
  return createStyles({
    root: {
      listStyle: "none",
      marginBottom: "3.125rem",
      display: "flex",
      marginTop: 0,
      paddingLeft: 0,
      alignItems: "center",
      overflowX: "scroll",
      [showLogoUp]: {
        order: 3,
        marginBottom: 0,
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {
  images: Product["images"]
  selectImage: SelectImage
  selectedImageIndex: SelectedImageIndex
}

const Images = ({ classes, images, ...other }: Props) => {
  const renderImage = (image: Thumbnail, index: number): JSX.Element => {
    return <Image {...image} index={index} key={index} {...other} />
  }

  const renderedImages: JSX.Element[] = images.map(renderImage)

  return <ul className={classes.root}>{renderedImages}</ul>
}

export default withStyles(styles)(Images)
