import { withStyles, createStyles, Theme } from "@material-ui/core"

import NavWithLogo from "../components/Layout/NavWithLogo"
import { showLogo } from "../components/Layout/NavWithLogo/styles"
import { navMarginBottom } from "./navDimensions"

const styles = ({ breakpoints: { up } }: Theme) => {
  return createStyles({
    root: {
      [up(showLogo)]: {
        marginBottom: navMarginBottom.lg,
      },
      [up("xl")]: {
        marginBottom: navMarginBottom.xl,
      },
    },
  })
}

export default withStyles(styles)(NavWithLogo)
