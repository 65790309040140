import React, { useState } from "react"
import { withStyles, WithStyles, createStyles, Theme } from "@material-ui/core"

import Product from "../types/Product"
import Layout from "../components/Layout"
import SeoWithLanguage from "../components/SeoWithLanguage"
import Hero from "./Hero"
import { SelectedImageIndex } from "./types"
import Content from "./Content"
import { showLogo } from "../components/Layout/NavWithLogo/styles"
import SelectedImageDesktop from "./SelectedImageDesktop"
import Nav from "./Nav"
import FullHeightPageContainer, {
  getMinHeight,
} from "../components/FullHeightPageContainer"
import { navMarginBottom } from "./navDimensions"
import { getProductLink } from "../utils/links"
import { useRedirectToPageWithSelectedLanguage } from "../utils/redirect"
import { QueryWithNodes } from "../types/queries"
import { LocalizedContent } from "../types/Localization"

interface QueryData {
  strapiProduct: Product
  allStrapiProduct: QueryWithNodes<LocalizedContent>
}

const styles = ({ breakpoints: { up, down } }: Theme) => {
  const showLogoUp = up(showLogo)
  return createStyles({
    body: {
      [showLogoUp]: {
        display: "flex",
        justifyContent: "flex-end",
        position: "relative",
        minHeight: getMinHeight("lg", navMarginBottom.lg),
      },
      [down("sm")]: {
        padding: 0,
        maxWidth: "100%",
      },
      [up("xl")]: {
        minHeight: getMinHeight("lg", navMarginBottom.xl),
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {
  data: QueryData
}

function ProductPage({
  data: {
    strapiProduct: product,
    allStrapiProduct: { nodes: localizedProducts },
  },
  classes,
}: Props) {
  const [selectedImageIndex, selectImage] = useState<SelectedImageIndex>(0)

  const { title, images } = product

  useRedirectToPageWithSelectedLanguage<LocalizedContent>(
    product,
    localizedProducts,
    getProductLink
  )

  return (
    <Layout nav={<Nav />} footer={null}>
      <SeoWithLanguage title={title} />
      <FullHeightPageContainer className={classes.body}>
        <Hero selectedImageIndex={selectedImageIndex} images={images} />
        <SelectedImageDesktop
          images={images}
          selectedImageIndex={selectedImageIndex}
        />
        <Content
          selectedImageIndex={selectedImageIndex}
          selectImage={selectImage}
          {...product}
        />
      </FullHeightPageContainer>
    </Layout>
  )
}

export default withStyles(styles)(ProductPage)
