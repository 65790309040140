import { withStyles, createStyles, Theme } from "@material-ui/core"

import { showLogo } from "../components/Layout/NavWithLogo/styles"
import SelectedImage from "./SelectedImage"

const styles = ({ breakpoints: { up } }: Theme) => {
  return createStyles({
    root: {
      display: "none",
      [up(showLogo)]: {
        position: "absolute !important",
        left: 0,
        top: "50%",
        transform: "translateY(-50%)",
        width: "37.5625rem",
        display: "block",
      },
      [up("xl")]: {
        width: "50.3125rem",
      },
    },
  })
}

export default withStyles(styles)(SelectedImage)
