import * as React from "react"

function SvgStar(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 19.969 19.07"
      {...props}
    >
      <path
        d="M19.637 8.124l-5.2 3.779 1.988 6.115a.8.8 0 01-1.237.9l-5.2-3.78-5.2 3.779a.8.8 0 01-1.237-.9l1.987-6.115-5.2-3.779a.8.8 0 01.473-1.454H7.24L9.227.554a.8.8 0 011.529 0l1.986 6.115h6.431a.8.8 0 01.471 1.454z"
        fill="inherit"
      />
    </svg>
  )
}

export default SvgStar
