import React from "react"

import BaseStars from "../../../../components/Stars"
import Star, { Props as StarProps } from "./Star"
import Product from "../../../../types/Product"

interface Props {
  stars: Product["stars"]
}

export default function Stars({ stars }: Props) {
  type StarWithSelectedStarProps = Omit<StarProps, "selectedStars" | "classes">

  const StarWithSelectedStars: React.FC<StarWithSelectedStarProps> = (
    props: StarWithSelectedStarProps
  ) => <Star selectedStars={stars} {...props} />

  return <BaseStars stars={5} Star={StarWithSelectedStars} />
}
