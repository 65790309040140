import React from "react"

import Thumbnail, { Props as ThumbnailProps } from "../components/Thumbnail"
import Product from "../types/Product"
import { SelectedImageIndex } from "./types"

export interface Props {
  images: Product["images"]
  selectedImageIndex: SelectedImageIndex
  classes?: ThumbnailProps["classes"]
}

export default function SelectedImage({
  images,
  selectedImageIndex,
  ...other
}: Props) {
  const image = images[selectedImageIndex]
  return (
    <Thumbnail
      imageData={image.localFile}
      alt={image.alternativeText}
      objectFit="contain"
      {...other}
    />
  )
}
