import React from "react"
import { withStyles, WithStyles, createStyles } from "@material-ui/core"

import StarSvg from "./StarSvg"

const styles = () => {
  return createStyles({
    root: {
      "& + &": {
        marginLeft: "0.25rem",
      },
    },
  })
}

export interface BaseProps {
  index: number
}

interface Props extends WithStyles<typeof styles>, BaseProps {}

const Star = ({ classes }: Props) => {
  return <StarSvg className={classes.root} />
}

export default withStyles(styles)(Star)
