import React from "react"
import { Button, Theme } from "@material-ui/core"
import { withStyles, WithStyles, createStyles } from "@material-ui/core"

import { showLogo } from "../../components/Layout/NavWithLogo/styles"
import Product from "../../types/Product"

const styles = ({ breakpoints: { up } }: Theme) => {
  return createStyles({
    root: {
      width: "100%",
      height: "auto",
      textAlign: "center",
      [up(showLogo)]: {
        width: "auto",
        marginBottom: "4rem",
        padding: "0.9063rem 3.9375rem",
        minWidth: "auto",
      },
      [up("xl")]: {
        marginBottom: "5rem",
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {
  buttonText: Product["buttonText"]
  buttonHref: Product["buttonHref"]
}

function BuyNowButton({ classes, buttonHref, buttonText }: Props) {
  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        classes={classes}
        href={buttonHref}
      >
        {buttonText}
      </Button>
    </div>
  )
}

export default withStyles(styles)(BuyNowButton)
