import React from "react"
import { withStyles, WithStyles, createStyles, Theme } from "@material-ui/core"

import Product from "../../types/Product"
import { SelectedImageIndex, SelectImage } from "../types"
import Images from "./Images"
import Info from "./Info"
import Body from "./Body"
import BuyNowButton from "./BuyNowButton"
import { showLogo } from "../../components/Layout/NavWithLogo/styles"
import { useIsDarkMode } from "../../utils/colors"

const styles = ({ breakpoints: { up } }: Theme) => {
  const showLogoUp = up(showLogo)
  const xl = up("xl")

  const borderRadius = "0.9375rem"
  const opacity: number = 0.5

  return createStyles({
    root: {
      padding: "3.375rem 2.25rem",
      borderTopLeftRadius: borderRadius,
      borderTopRightRadius: borderRadius,
      position: "relative",
      overflow: "hidden",
      backdropFilter: "blur(0.8125rem) brightness(115%)",
      [showLogoUp]: {
        width: "34.8125rem",
        display: "flex",
        flexDirection: "column",
        flexShrink: 0,
        padding: "2.8125rem",
      },
      [xl]: {
        width: "47.6875rem",
        padding: "3.5625rem 4.875rem",
      },
    },
    light: {
      backgroundColor: `rgba(255, 255, 255, ${opacity})`,
    },
    dark: {
      backgroundColor: `rgba(0, 0, 0, ${opacity})`,
    },
  })
}

interface Props extends WithStyles<typeof styles>, Product {
  selectedImageIndex: SelectedImageIndex
  selectImage: SelectImage
}

const Content = ({
  classes,
  selectedImageIndex,
  selectImage,
  images,
  stars,
  title,
  subtitle,
  description,
  buttonHref,
  buttonText,
}: Props) => {
  const isDarkMode: boolean = useIsDarkMode()

  const getClasses = (): string => {
    const colorClass: string = isDarkMode ? classes.dark : classes.light
    return `${classes.root} ${colorClass}`
  }

  const className: string = getClasses()

  return (
    <div className={className}>
      <Images
        images={images}
        selectImage={selectImage}
        selectedImageIndex={selectedImageIndex}
      />
      <Info title={title} stars={stars} />
      <Body subtitle={subtitle} description={description} />
      <BuyNowButton buttonHref={buttonHref} buttonText={buttonText} />
    </div>
  )
}

export default withStyles(styles)(Content)
