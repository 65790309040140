import React from "react"
import { withStyles, WithStyles, createStyles } from "@material-ui/core"

import HeroWithLogo from "../components/HeroWithLogo"
import SelectedImage, { Props as SelectedImageProps } from "./SelectedImage"

const styles = () => {
  return createStyles({
    root: {
      marginBottom: "0.9375rem",
      flexDirection: "column",
      alignItems: "center",
    },
    logo: {
      marginBottom: "0.5rem",
    },
  })
}

interface Props
  extends WithStyles<typeof styles>,
    Omit<SelectedImageProps, "classes"> {}

const Hero = ({ classes, ...other }: Props) => {
  return (
    <HeroWithLogo classes={classes}>
      <SelectedImage {...other} />
    </HeroWithLogo>
  )
}

export default withStyles(styles)(Hero)
